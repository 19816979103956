.metodos-pago {
	position: relative;
	padding: 138px 0 0 0;
	
	@include breakpoint(tablet) {
		padding-top: 150px;
	}	
	@include breakpoint(laptop) {
		padding-top: 168px;
	}	
	@include breakpoint(desktop) {
		padding-top: 192px;
	}
}

.pago {
	position: relative;
	background: $white;

	&_title {
		font-size: 26px;
		color: $white;
		background-color: $violet;
		text-align: center;
		letter-spacing: 2px;
		padding: 12px 0;
		
		.metodos-pago & {
			font-size: 26px;
			font-weight: normal;
			color: $violet;
			background: none;
			border-top: 1px $grey solid;
			padding: 18px;
			text-align: center;
		}
	}

	&_content {
		position: absolute;
		bottom: 40px;
		width: 100%;
		background: rgba(255,255,255, .7);
		
		.metodos-pago & {
			bottom: 0;
		}
		
		.link {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			padding: 40px 40px 0;
	
			.ml-comenzar {
				background: $violet;
				color: $white;
				display: inline-block;
				text-align: center;
				width: 70%;
				margin: 20px auto;
				font-weight: 700;
			}

			.logo-ml {
				width: 160px;
			}

			p {
				text-align: center;
				margin-bottom: 20px;
			}
		}

		.phrase {
			padding: 40px;
			text-align: center;
			
			h3 {
				font-size: 28px;
				font-weight: 400;
			}
			p {
				text-transform: uppercase;
				font-size: 18px;
				font-weight: 700;
				color: $violet;
			}
		}

		.pagar {
			padding: 40px;
			text-align: center;
			background: rgba(55,22,58, .9);
			
			.button__rayen {
				width: 250px;
				border-color: $orange;
				background: $orange;

				&:before {
					color: $orange;
				}
			}
		}
		
	}

	img {
		height: 800px;
		width: 100%;
		object-fit: cover;
		object-position: center;
	}

	@include breakpoint(mobilelandscape) {

		img {
			object-position: center top;
		}
	}
	@include breakpoint(phablet) {
		
		img {
			object-position: center top;
		}
	}

	@include breakpoint(tablet) {

		&_title {
			position: absolute;
			top: 32px;
			padding: 12px 26px;
			
			.metodos-pago & {
				position: static;
			}
		}		

		&_content {
			display: flex;
	        justify-content: space-between;
	        flex-wrap: wrap;
			padding: 40px;

			.link {
				padding: 0;
				order: 2;
				align-items: flex-start;
				flex: 25% 0 0;
				
				.metodos-pago & {
					order: -1;
					flex: 50% 0 0;
				}
	
				.ml-comenzar {
					margin: 0 0 26px 0;
					width: 100%;
				}

				.logo-ml {
					width: 160px;
				}				

			}

			.phrase {
				padding: 0;
				text-align: left;
				flex: 40% 0 0;
				
				h3 {
					font-size: 36px;
				}
			}

			.pagar { 
				order: 3;
				flex: 100% 0 0;
			}
			
			.metodos-pago & {
				padding: 40px 0 0 0;
				
				.link {
					padding: 0 0 40px 40px;
				}
				.phrase {
					padding: 0 40px 40px 0;
				}
			}			
		}

		img {
			height: 600px;
		}		 	
	}
	@include breakpoint(laptop) {

		&_title {

			.metodos-pago & {
				font-size: 32px;
				padding: 48px 0;
			}
		}		

		&_content {
			padding: 40px 86px;
			
			.metodos-pago & {
				padding: 60px 0 0 0;
				
				.link {
					padding: 0 0 60px 60px;

					p {
						font-size: 20px;
					}
				}
				.phrase {
					padding: 0 60px 60px 0;
				}
				.pagar {
					padding: 60px;
				}
			}					
		}	

		img {
			height: 700px;
		}		 	
	}
	@include breakpoint(desktop) {

		&_title {
			top: 62px;
		
			.metodos-pago & {
				font-size: 32px;
				padding: 48px 0;
			}
		}

		&_content {

			.phrase {
				
				h3 {
					font-size: 54px;
				}
			}	

			.metodos-pago & {
				// padding: 60px 0 0 0;
				
				.link {
					padding-left: 10vw;
				}
				.phrase {
					padding-right: 10vw;
				}
				.pagar {
					padding: 60px;
				}
			}				
		}	

		img {
			height: 800px;
		}			
	}
	@include breakpoint(bigdesktop) {

		&_title {

			.metodos-pago & {
				font-size: 48px;
				// padding: 48px 0;
			}
		}

		&_content {
			padding: 40px 10vw;
			
			.link {
	
				.ml-comenzar {
					width: 70%;
				}
			}
		}
	}
}