.treatment {
	position: relative;
	background: $white;
	padding-top: 138px;

	
	.breadcrumbs {
		padding: 20px;
		border-top: 1px $grey-light solid;
		font-style: italic;
		
		span, a {
			padding: 0 8px;
			color: $grey;
		}
	}
	&_header {
		padding: 20px;
		background: 
			url('../img/bg-tratamiento-header-lines.svg') no-repeat,
			url('../img/bg-tratamiento-header-circle.svg') no-repeat
		;
		background-size: 300px, 120px;
		background-position: 120% 60px, 20px 20px;

		.quote {
			padding: 40px 30px;
			text-align: center;
			
			p {
				font-weight: bold;
				line-height: 28px;
				color: $grey;
				
				span {
					color: $violet-old;
					font-size: 28px;
					line-height: 0;
				}
			}
		}

		.title {
			text-align: center;

			h2 {
				font-size: 36px;
				color: $violet;	
				font-weight: normal;
			}
			h4 {
				font-family: $font-cursive;
				font-size: 22px;
				color: $grey-light;
				font-weight: normal;
			}
		}
	}

	&_item {
		text-align: center;

		picture {
			display: block;
			height: 200px;
			overflow: hidden;
		}

		h2 {
			font-size: 24px;
			margin: 12px 0;
			text-transform: uppercase;
			color: $brown;
		}
	}

	&_menu {
		margin-bottom: 12px; 

		li {
	
			a {
				font-size: 18px;
				line-height: 28px;
				color: $grey-light;
				text-transform: uppercase;
			}
		}
	}

	img {
		height: 200px;
		width: 100%;
		object-fit: cover;
		object-position: center;
	}

	@include breakpoint(mobilelandscape) {

		img {
			// object-position: center top;
		}
	}
	@include breakpoint(phablet) {
		
		img {
			// object-position: center top;
		}
	}

	@include breakpoint(tablet) {
	padding-top: 150px;

		&_header {
			margin: 0 32px 40px 32px;
			background-size: 300px, 200px;
			background-position: 100% 20px, 20px 60px;
			
			.quote {
				padding: 70px 70px 100px 70px;
			}

			.title {

				h2 {
					font-size: 48px;
				}
				h4 {
					font-size: 28px;
				}
			}			
		}
		
		&_items {
			display: grid;
			grid-template-columns: repeat(3, 1fr);
			grid-gap: 0;
		}

		&_item {
			position: relative;
			cursor: pointer;
			height: 380px;
			overflow: hidden;
			
			&:after {
				content: "";
				position: absolute;
				bottom: 0;
				left: 0;
				width: 100%;
				height: 100px;
				background: linear-gradient(to top, rgba(145,84,49, 1), rgba(145,84,491, 0));
				z-index: 1;
				opacity: .6;
			}

			picture {
				height: auto;
				// display: block;
				// overflow: hidden;
			}

			h2 {
				position: absolute;
				width: 100%;
				bottom: 30px;
				margin: 0;
				z-index: 3;	
				// font-size: 24px;
			}

			&:hover .treatment_menu {
				position: absolute;
				opacity: 1;
				visibility: visible;
				height: 100%;
				top: 0;
				bottom: 0;
				left: 0;
				right: 0;
				background: rgba(255,255,255,.8);
				z-index: 6;
			}
		}

		&_menu {
			margin-bottom: 12px;
			opacity: 0;
			visibility: hidden;
			height: 0;
			margin: 0;
			display: flex;
			justify-content: center;
			align-items: center;
			transition: all .3s ease-in-out;

			li {
		
				a {
					// font-size: 18px;
					// line-height: 28px;
					// color: $grey-light;
					
					&:hover {
						color: $violet;
					}
				}
			}

			&.active {
				position: absolute;
				opacity: 1;
				visibility: visible;
				height: 100%;
				top: 0;
				bottom: 0;
				left: 0;
				right: 0;
				background: rgba(255,255,255,.8);
			}
		}

		img {
			height: 380px;
			width: 100%;
			object-fit: cover;
			object-position: center;
		}

		 	
	}
	@include breakpoint(laptop) {
	padding-top: 168px;
	
		&_header {
			margin: 0 32px;
			padding-bottom: 40px;
			background-size: 600px, 370px;
			// background-position: 100% 20px, 20px 60px;
			
			.quote {
				padding: 70px 100px 100px 120px;
				
				p {
					line-height: 62px;
					font-size: 24px;
					
					span {
						font-size: 62px;
					}
				}				
			}

			.title {
				// text-align: center;

				h2 {
					font-size: 64px;
				}

				h4 {
					font-size: 36px;
				}
			}
		}

		&_item {
			// position: relative;
			// cursor: pointer;
			height: 480px;
			// overflow: hidden;
		}

		img {
			height: 480px;
		}		 	
	}
	@include breakpoint(desktop) {
	padding-top: 192px;
			
	}
	@include breakpoint(bigdesktop) {
		
		&_header {
			// margin: 0 32px;
			padding-bottom: 80px;
			// background-size: 600px, 370px;
			// background-position: 100% 20px, 20px 60px;
			
			.quote {
				padding: 70px 10vw 140px 10vw;
			}
		}

		&_item {
			// position: relative;
			// cursor: pointer;
			height: 580px;
			// overflow: hidden;
		}

		img {
			height: 580px;
		}		

	}
}