.diferencial {
	position: relative;
	background: $white;

	&_title {
		font-size: 32px;
		color: $violet;
		font-weight: 400;
		text-align: center;
		letter-spacing: 2px;
		
		span {
			background: $violet-old;
			color: $white;
			padding: 0 22px 0 12px;
		}
	}

	&_group {
	 	background-image: url('../img/bg-diferencial-bottom.svg'), url('../img/bg-diferencial-tr.svg'), url('../img/bg-diferencial-bl.svg'), linear-gradient(72deg, #dacae4, #fff, #dacae4);
	 	background-repeat: no-repeat;
	 	background-size: cover, cover, cover, 100%;
	 	background-position: bottom center, top right, center left, center;
		margin-top: -12px;
		padding: 40px 20px 20px;
	}

	&_item {
		position: relative;
		margin-bottom: 20px;
		
		header {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translateY(-50%) translateX(-50%);
			width: 70%;
			text-align: center;
			color: $grey;
			
			h3 {
				background: rgba(255,255,255,.5);
				padding: 52px 42px;
			}

			p {
				background: $white;
				padding: 78px 56px;
			}
		}
	}

	img {
		height: 520px;
		width: 100%;
		object-fit: cover;
		object-position: center;
	}

	@include breakpoint(mobilelandscape) {

		img {
			object-position: center top;
		}
	}
	@include breakpoint(phablet) {
		
		img {
			object-position: center top;
		}
	}

	@include breakpoint(tablet) {

		&_title {
			font-size: 46px;
			
			span {
				padding: 0 32px 0 18px;
			}
		}		

		&_group {
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			grid-gap: 40px;
			padding: 82px;
		 	background-position: bottom left, top right, center left, center;
		}		

		&_item {
			margin: 0px;
		}

		img {
			height: 520px;
		}		 	
	}
	@include breakpoint(laptop) {


		&_item {
			// position: relative;
			// margin-bottom: 20px;
			
			header {
				// position: absolute;
				// top: 50%;
				// left: 50%;
				// transform: translateY(-50%) translateX(-50%);
				// width: 70%;
				// text-align: center;
				
				h3 {
					// background: rgba(255,255,255,.5);
					// padding: 52px 42px;
					// color: $grey;
					font-size: 24px;
				}

				p {
					// background: $white;
					padding: 78px;
				}
			}
		}

		img {
			height: 600px;
		}		 	
	}
	@include breakpoint(desktop) {
		
	}
	@include breakpoint(bigdesktop) {


		&_group {
			padding: 82px 10vw;
		}
	}
}