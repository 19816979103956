.contact {
	background: $grey-lighter;
	padding:60px;
	
	&_top {
		position: relative;
		background: rgb(255, 255, 255);
		padding: 138px 0 0 0;
	}

	.frase {
		color: $violet;
		font-size: 18px;
		text-align: center;
		border-bottom: 1px $grey solid;
		padding-bottom: 20px;
	}

	&_title {
		font-size: 26px;
		font-weight: normal;
		color: $violet;
		border-top: 1px $grey solid;
		border-bottom: 1px $grey solid;			
		padding: 18px;
		text-align: center;
		margin-bottom: 30px;
	}

	&_address {
		color: $grey;
		padding: 0 30px;
		
		li {
			margin-bottom: 36px;
		}

		h4 {
			font-weight: 700;
			font-size: 22px;
			margin-bottom: 12px;
			color: $violet;
		}

		p {
			color: $violet-footer-light;
			font-size: 16px;
			margin-bottom: 6px;

			a {
				color: $violet-footer-light;
				
				&:hover {
					color: $white;
					
					.contact_top & {
						color: $orange;
					}
				}
			}

			strong {
				font-weight: 400;
				color: $violet-footer-dark;
			}

			&.tel {
				background: url('../img/bg-address-tel.svg') no-repeat;
				background-size: 18px;
				background-position: center left;
				padding-left: 28px;
			}

			&.street {
				background: url('../img/bg-address-street.svg') no-repeat;
				background-size: 16px;
				background-position: center left;
				padding-left: 28px;
			}

			&.street2 {
				padding-left: 28px;
			}

			&.email {
				background: url('../img/bg-address-email.svg') no-repeat;
				background-size: 18px;
				background-position: center left;
				padding-left: 28px;
			}
		}
	}
	
	.footer_menu {
		background: none;
		
		li { 
			
			.turnos, .whatsapp {
				margin: 0 auto;
			}

			.whatsapp {
				background: $violet-dark;
				padding: 12px 24px;
				
				span {
					color: $white;
					transition: color .3s ease;
				}

				&:hover {
					
					span {
						color: $violet-old;
					}
				}
			}

			&:first-child {
				margin-bottom: 24px;
			}
		}
	}

	&_mp {
		border-top: 1px $violet solid;
		margin-top: 36px;
		padding: 60px 0 40px;
		text-align: center;
		
		.mercadopago {
			width: 200px;
			height: 52px;
			margin: 30px auto;
		}

		.button__rayen {
			background: $red;
			border-color: $red;
			font-weight: bold;
			
			&:before {
				color: $red;
			}
		}
	}
	
	+ .contact#contacto {
		background: $white;
		padding: 30px;
	}	

	@include breakpoint(phablet) {
		
		&_address {
			display: grid;
			grid-template-columns: repeat(2, 1fr);
		}

		.footer_menu {
			// background: none;
			
			li { 
				
				&:first-child {
					margin: 0;
				}
			}
		}		

		&_mp {
			padding: 60px 160px 0;		
		}
	}

	@include breakpoint(tablet) {
	
		&_top {
			padding: 150px 30px 0;
		}

		&_address {
			display: grid;
			grid-template-columns: repeat(2, 1fr);
		}

		.footer_menu {
			padding: 30px 0;
		}

		#contactForm {
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			grid-gap: 18px;

			input { 
				grid-column: 1 / 3;
				margin: 0;
				
				&#telefono {
					grid-column: 1 / 2;
				}
				&#fecha {
					grid-column: 2 / 3;	
				}
			}

			textarea {
				grid-column: 1 / 3;
				margin: 0;
				min-height: 140px;
			}

			button {
				grid-column: 2 / 3;	
				justify-self: end;
				max-width: 350px;
			}
		}		

		&_mp {
			padding: 30px 0 0;		
			display: flex;
			flex-wrap: wrap;
	        justify-content: space-between;
	        text-align: left;
			
			p {
				flex: 100% 0 0;
				margin-bottom: 18px;
			}

			.mercadopago {
				margin: 0;
				// flex: 50% 0 0; 
			}
			.button__rayen {
				margin: 0;
				flex: 250px 0 0;
			}
		}		
	}

	@include breakpoint(laptop) {

		&_top {
			padding: 168px 60px 0;

			.contact_title {
				margin-bottom: 60px;
			}			
		}
		
		&_address {

		
			li {
				margin-bottom: 48px;
			}			

			h4 {
				margin-bottom: 32px;
			}
		}

		.frase {
			font-size: 24px;
		}

		&_title {
			font-size: 32px;
		}
		
		+ .contact#contacto {
			padding: 60px;
		}			
	}

	@include breakpoint(desktop) {

		&_top {
			padding: 192px 60px 0;
	
		}
	}

	@include breakpoint(bigdesktop) {
		padding: 80px 16vw;

		&_top {
			padding: 192px 16vw 0;

		}
		
		+ .contact#contacto {
			padding: 60px 16vw;
		}		
	}
}