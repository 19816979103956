.destacados {
	position: relative;
	background: $white;

	&_title {
		font-size: 32px;
		color: $violet;
		font-weight: 400;
		text-align: center;
		text-transform: uppercase;
		letter-spacing: 2px;
		padding: 42px 20px;
	}

	&_notes {
		margin: 0 20px 20px;
	}

	&_note {
		padding-top: 36px;
		border-top: 1px $violet solid;
		
		& + article {
			margin-top: 36px;
		}
		
		header {
			background: url('../img/bg-destacados-title.svg') no-repeat;
			background-position: left 8px;
			background-size: 32px;
			padding: 0 0 48px 58px;
			
			h3 {
				font-size: 32px;
				font-weight: 400;
				
				a {
					color: $violet;
					
					&:hover {
						color: $violet-footer-light;
					}
				}
			}

			p {
				padding: 0px;
				font-weight: bold;
				font-size: 18px;
				color: $grey;
			}
		}
		.content {
			margin-bottom: 42px;
			
			p {
				margin-bottom: 12px;
			}
		}

	}

	img {
		height: 240px;
		width: 100%;
		object-fit: cover;
		object-position: center;
	}

	&_tratamientos {
		margin: 20px;
		padding: 20px;
		box-shadow: 0px 0px 20px -10px $grey;
		
		hgroup {
			text-align: center;
			letter-spacing: 2px;
		
			h2 {
				font-size: 28px;
				font-weight: 400;
				text-transform: uppercase;
				padding: 12px 0;
				color: $white;
				background: $ocean;
			}

			h4 {
				padding: 32px;
				color: $grey;
			}
		}

		.grid {
			
			.tratamiento_item {
				background-size: 350px;
				background-position: center;
				position: relative;
				padding: 230px 10px 10px;
				display: block;
				transition: background 2s ease-out;
				
				h3 {
					background: $white;
					color: $violet;
					font-size: 18px;
					text-align: center;
					padding: 28px 0;
				}
			}
		}
	}

	@include breakpoint(mobilelandscape) {

		&_tratamientos {
			
			.grid {		
				display: grid;
				grid-template-columns: repeat(2, 1fr);
				grid-gap: 20px;
			}
		}
	}
	@include breakpoint(phablet) {


		&_notes {
			border-top: 1px $violet solid;
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			grid-gap: 20px;
			margin: 0 20px;
		}			

		&_note {
			border: none;
			display: flex;
			flex-direction: column;
			
			& + article {
				margin: 0px;
			}	

			.content {
				order: 3;
				margin-bottom: 0;
			}

			picture {
				order: 2;
				margin-bottom: 42px;
			}
		}		

		&_tratamientos {
			
			.grid {		
				display: grid;
				grid-template-columns: repeat(2, 1fr);
				grid-gap: 20px;
			}
		}	
	}

	@include breakpoint(tablet) {
		padding-bottom: 86px;

		&_title {
			font-size: 46px;
		}		

		&_notes {
			grid-gap: 40px;
			margin: 0 82px;
		}		

		&_note {

			header {
				background-size: 32px;
				background-position: left 12px;
				padding: 0 0 48px 58px;
				
				h3 {
					font-size: 42px;
				}
			}
		}

		img {
			height: 340px;
		}

		&_tratamientos {
			margin: 40px 86px 0;
			
			.grid {		
				grid-template-columns: repeat(4, 1fr);
				grid-gap: 0;				
				
				.tratamiento_item {
					padding: 260px 0 0 0;
				}			
			}
		}
	}
	@include breakpoint(laptop) {

		&_title {
			font-size: 54px;
		}

		&_notes {
			grid-gap: 60px;
		}

		img {
			height: 460px;
		}		 	

		&_tratamientos {
			padding: 26px;
		
			hgroup {
			
				h2 {
					font-size: 34px;
					padding: 22px 0;
				}

				h4 {
					padding: 32px 0;
					font-size: 24px;
				}
			}
			
			.grid {		
				
				.tratamiento_item {
					padding: 260px 0 0 0;

					h3 {
						font-size: 24px;
					}

					&:hover {
						background-size: 400px;
					}
				}				
			}
		}		
	}
	@include breakpoint(desktop) {

		&_tratamientos {
			margin: 60px 86px 0;
		}

		img {
			height: 520px;
		}			
	}
	@include breakpoint(bigdesktop) {

		&_notes {
			margin: 0 10vw;
		}	

		&_tratamientos {
			margin: 60px 10vw 0;
			
			.grid .tratamiento_item {
				background-size: 400px;
			}			
		}
	}
}