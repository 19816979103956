// Fonts
$font-base:	'EB Garamond', serif;
$font-cursive: 'Cookie', cursive;

// Colors
$white: #fff;
$black: #000;
$violet: #883c87;
$violet-light: #be81b7;
$violet-old: #b8a7c4;
$violet-footer: #dacae4;
$violet-dark: #452567;
$violet-footer-light: #8f839b;
$violet-footer-dark: #6c6377;
$ocean: #009490;
$green: #3e8037;
$red: #b51739;
$grey: #5d5b5e;
$grey-light: #70706f;
$grey-lighter: #f0f1f6;
$grey-lighter-form: #f9f8f8;
$brown: #4f3626;
$brown-light: #915431;
$orange: #f7b334;