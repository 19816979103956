.nosotros {
	background: $white;
	padding-top: 138px;

	&_header {
		border-top: 1px $grey-light solid;
		padding: 20px;

		.quote {
			padding: 40px 30px;
			text-align: center;
			
			p {
				font-weight: bold;
				line-height: 28px;
				color: $grey;
				
				span {
					color: $violet-old;
					font-size: 28px;
					line-height: 0;
				}
			}
		}
	}

	&_figure {
		display: none;
	}

	&_content {
		padding: 30px;
		background: $grey-lighter-form url('../img/bg-nosotros-title.svg') no-repeat;
		background-size: contain;
		background-position: center 120px;
		position: relative;

		.top-content {
			padding: 12px 0 24px;
			border-bottom: 1px $violet solid;
			
			h2 {
				color: $violet;
				font-size: 28px;
				font-weight: 400;
			}
			p {
				font-weight: bold;
			}

		}
	}

	&_item {
		padding-top: 80px;
		
		.top {
			border-bottom: 1px $violet solid;
			padding-bottom: 12px;
			margin-bottom: 12px;
			
			h4 {
				color: $violet-old;
				font-size: 32px;
			}
			p {
				font-weight: 700;
			}
		}

		.content {
			
			p {
				margin-bottom: 12px;
			}
		}
	}

	&_footer {
		margin: 48px 0;
		color: $violet;
	}

	&_destacado {
		font-weight: 700;
		text-align: center;
	}

	&_news {
		padding: 30px;
		
		h3 {
		    font-size: 26px;
		    font-weight: normal;
		    color: $violet;
		    border-top: 1px $grey solid;
		    border-bottom: 1px $grey solid;
		    padding: 18px;
		    text-align: center;
		    margin-bottom: 30px;
		}		

		.news_item {
			
			header {
				margin-bottom: 30px;
				
				h2 {
					font-size: 20px;
					color: $grey;
					margin-bottom: 32px;
				}
			}
		}

		.news_gallery {
			
			figure {
				margin-bottom: 24px;
			}
		}
	}

	@include breakpoint(phablet) {

		&_news {
			padding: 30px;
			
			h3 {
			    // font-size: 26px;
			    // font-weight: normal;
			    // color: $violet;
			    // border-top: 1px $grey solid;
			    // border-bottom: 1px $grey solid;
			    // padding: 18px;
			    // text-align: center;
			    // margin-bottom: 30px;
			}		

			.news_item {
				display: flex;
				
				header {
					flex: 60% 0 0;
					padding-right: 30px;
				}
			}
		}
	}
	@include breakpoint(tablet) {
		padding-top: 150px;

		&_header {
			margin: 0 32px 40px 32px;
			background-size: 300px, 200px;
			background-position: 100% 20px, 20px 60px;
			
			.quote {
				padding: 70px 70px 100px 70px;
			}			
		}

		&_figure {
			display: block;
			position: absolute;
			left: -120px;
			top: 100px;
			width: 55%;
			max-width: 650px;
			z-index: 2;
		}		

		&_content {
			padding: 60px 0 60px 60px;
			background-position-y: 180px;
	
			.top-content {
				text-align: right;
				padding-right: 200px;
				padding-bottom: 50px;
				
				h2 {
					font-size: 38px;
				}

				p {
					font-size: 16px;
				}
			}

			.nosotros_item {
				padding: 80px 0 0 100px;
				
				.top {
					text-align: right;
					padding-right: 100px;
					
					h4 {
						font-size: 42px;
					}
				}

				.content {
					padding: 0 100px 0 300px;
				}
			}

			.nosotros_footer, .nosotros_destacado {
				padding-right: 60px;
			}
		}


		&_news {

			.news_item {
				// display: flex;
				
				header {
					flex: 35% 0 0;
					// padding-right: 30px;
				}
	
				.news_gallery {
					display: flex;
					justify-content: space-between;
					flex: 65% 0 0;
					
					img {
						height: 210px;
						object-fit: cover;
					}
				}
			}
		}		
	}
	@include breakpoint(laptop) {
		padding-top: 168px;
	
		&_header {
			margin: 0 32px;
			padding-bottom: 40px;
			
			.quote {
				padding: 70px 100px 100px 120px;
				
				p {
					line-height: 62px;
					font-size: 24px;
					
					span {
						font-size: 62px;
					}
				}				
			}
		}	

		&_content {
			// padding: 60px 0 60px 60px;
			// background-position-y: 180px;
	
			.top-content {
				// text-align: right;
				// padding-right: 200px;
				// padding-bottom: 50px;
				
				h2 {
					font-size: 46px;
				}

				p {
					// font-size: 16px;
				}
			}
		}		

		.nosotros_item {
			// padding: 80px 0 0 100px;
			
			.top {
				// text-align: right;
				// padding-right: 100px;
				
				// h4 {
				// 	font-size: 42px;
				// }
			}

			.content {
				padding-left: 400px;
			}
		}	

		&_news {
			// padding: 30px;
			
			h3 {
			    // font-size: 26px;
			    // font-weight: normal;
			    // color: $violet;
			    // border-top: 1px $grey solid;
			    // border-bottom: 1px $grey solid;
			    // padding: 18px;
			    // text-align: center;
			    // margin-bottom: 30px;
			}		

			.news_item {
				// display: flex;
				
				header {
					// flex: 35% 0 0;
					// padding-right: 30px;
				}
	
				.news_gallery {
					// display: flex;
					// justify-content: space-between;
					// flex: 65% 0 0;
					
					img {
						height: 270px;
						// object-fit: cover;
					}
				}
			}
		}				 	
	}
	@include breakpoint(desktop) {
		padding-top: 192px;
			
	}
	@include breakpoint(bigdesktop) {
		// padding: 60px 10vw;
		
		&_header {
			padding-bottom: 80px;
			
			.quote {
				padding: 70px 10vw 140px 10vw;
			}
		}

		&_news {
			padding: 60px 10vw;
			
			.news_item {
				justify-content: space-between;
				// display: flex;
				
				header {
					// flex: 35% 0 0;
					// padding-right: 30px;
				}
	
				.news_gallery {
					// display: flex;
					// justify-content: space-between;
					flex: 55% 0 0;
					
					img {
						// height: 270px;
						// object-fit: cover;
					}
				}
			}
		}
	}
}