.hero {
	min-height: 100vh;
	position: relative;
	overflow: hidden;

	.swiper-slide {

		&:before {
			content: '';
			width: 100%;
			height: 50vh;
			background-image: 
			    // top, transparent
			    linear-gradient(
			      rgba(255,255,255, .9) 65%, 
			      rgba(255,255,255, 0.0)
			    );
			background-repeat: no-repeat;
			background-position: top;
			position: absolute;
			top: 0;
			left: 0;
			z-index: 2;
			
		}
		&:after {
			content: '';
			width: 100%;
			height: 30vh;
			background-image: 
			    // top, transparent
			    linear-gradient(
			      rgba(255,255,255, 0.0),
			      rgba(255,255,255, 1)
			    );
			background-repeat: no-repeat;
			background-position: top;
			position: absolute;
			bottom: 0;
			left: 0;
			z-index: 2;
		}
		
	}

	.swiper-button-next, .swiper-button-prev {
		background: url('../img/bg-arrow.svg') no-repeat center;
		width: 42px;
		height: 42px;
		right: 0;
	}
	
	.swiper-button-prev {
		transform: rotate(.5turn);
		left: 0;
	}

	img {
		height: 740px;
		width: 100%;
		object-fit: cover;
		object-position: center;
	}
	
	&_tagline {
		position: absolute;
		top: 180px;
		left: 0;
		width: 100%;
		z-index: 3;
		text-align: center;
		height: calc(80vh - 140px);
		display: flex;
		flex-direction: column;
        justify-content: space-between;

		h1 {
			font-size: 36px;
			font-weight: 400;
			color: $violet;
			padding: 0 32px;
		}

		h3 {
			font-size: 32px;
			color: $white;
			background: $violet-light;
		}
	}

	@include breakpoint(mobilelandscape) {

		img {
			height: 420px;
		}
	}
	@include breakpoint(phablet) {
		
		&_tagline {
			border: 1px $violet solid;
			border-top: none;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;
			margin: 36% 40px 16% 40px;
			height: auto;
			width: auto;
			padding: 0 40px 40px;
			justify-content: space-between;

			h1 {
				margin-top: -26px;
				position: relative;
				
				&:before {
					content: "";
					background: $violet;
					height: 1px;
					width: 40px;
					position: absolute;
					top: 50%;
					left: -41px;
					right: 0;
					
				}				
				&:after {
					content: "";
					background: $violet;
					height: 1px;
					width: 40px;
					position: absolute;
					top: 50%;
					right: -41px;
				}
			}
			h3 {
				padding: 0 86px;
			}
		}		

		img {
			height: 1024px;
		}
	}

	@include breakpoint(tablet) {

		&_tagline {
			border: 1px $violet solid;
			border-top: none;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;
			margin: 20% 40px 10% 40px;
			height: auto;
			width: auto;
			padding: 0 40px 40px;
			justify-content: space-between;

			h1 {
				margin-top: -26px;
				position: relative;
				
				&:before {
					content: "";
					background: $violet;
					height: 1px;
					width: 40px;
					position: absolute;
					top: 50%;
					left: -41px;
					right: 0;
					
				}				
				&:after {
					content: "";
					background: $violet;
					height: 1px;
					width: 40px;
					position: absolute;
					top: 50%;
					right: -41px;
				}
			}
			h3 {
				padding: 0 86px;
			}
		}		

		img {
			height: 800px;
		}		 	
		
	}
	@include breakpoint(laptop) {
		
		.swiper-slide {
			&:before {
				height: 50vh;
			}
			&:after {
				height: 40vh;
			}	
		}	

		&_tagline {
			margin: 18% 40px 10% 40px;
			
			h1 {
				font-size: 48px;
				margin-top: -35px;
				
				&:before {
					width: 110px;
				}				
				&:after {
					width: 110px;
				}
			}	

			h3 {
				font-size: 40px;
			}	
		}
	}
	@include breakpoint(desktop) {
		
		img {
			height: 960px;
		}		
	}
	@include breakpoint(bigdesktop) {
		height: 100vh;

		&_tagline {
			margin: 24vh 10vw 8vh 10vw;
		}
	}
}