*, *:before, *:after { box-sizing: border-box; }

/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary, time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
img {
	display: block;
	width: 100%;
}
h1, h2, h3, h4, h5, h6 {
	font-weight: 700;
	line-height: 1.4;
}
ul, li {
	list-style: none;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section, picture, svg {
	display: block;
}
body {
	font: 400 16px $font-base;
	color: $black;
	line-height: 1;
	background: $white;
	position: relative;
}
a {
	text-decoration: none;
	transition: all .4s ease;
}
p {
	line-height: 1.4;
}
strong {
	font-weight: 700;
}
input, textarea {
	width: 100%;	
	background: $grey-lighter-form;
	padding: 12px;
	margin-bottom: 18px;
	border: 2px #e9e9e9 solid;
	color: $violet-footer-dark;
	font-family: $font-base;
	font-size: 14px;
	transition: all .2s ease-in-out;
	
	&::placeholder {
		color: $grey;
	}	
	&::-moz-placeholder {
	  opacity: 1;
	}
	&:focus {
		border-color: $violet;
		
		&::placeholder {
			color: $violet;
		}
	}
	&.error {
		border-color: red;
	}
}
.button__rayen {
	overflow: hidden;
	padding: 0;
	width: 100%;
	border: 2px solid;
	border-color: $violet;
	font-weight: 500;
	font-size: 18px;	
	font-family: $font-base;
    background: $violet;
    vertical-align: middle;
    position: relative;
    z-index: 1;
    -webkit-backface-visibility: hidden;
    -moz-osx-font-smoothing: grayscale;
    cursor: pointer;

	&:before, & > span {
		padding: 1em 1.6em;
		transition: transform 0.3s;
		transition-timing-function: ease;
		transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
	}    
	
	& > span {
	    display: block;
	    vertical-align: middle;
	    color: $white;
	}

	&:before {
		color: $violet;
		content: attr(data-text);
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: $white;
		transform: translate3d(-100%, 0, 0);	    

	}

	&:hover {

		&:before {
		    transform: translate3d(0, 0, 0);
		}
		& > span {
			transform: translate3d(0, 100%, 0);
		}
	}
}

a.button__rayen { 
	display: block;
	margin: 0 auto; 
	text-align: center;
}