.tech {
	position: relative;
	background: $white;
	padding-top: 138px;

	&_header {
		border-top: 1px $grey-light solid;
		padding: 20px;
		// background: 
		// 	url('../img/bg-tratamiento-header-lines.svg') no-repeat,
		// 	url('../img/bg-tratamiento-header-circle.svg') no-repeat
		// ;
		// background-size: 300px, 120px;
		// background-position: 120% 60px, 20px 20px;

		.quote {
			padding: 40px 30px;
			text-align: center;
			
			p {
				font-weight: bold;
				line-height: 28px;
				color: $grey;
				
				span {
					color: $violet-old;
					font-size: 28px;
					line-height: 0;
				}
			}
		}
	}

	&_item {
		background: linear-gradient(to top, #f4f4f4, #fff);
		margin: 0 30px 30px;
		padding: 30px;
		text-align: center;

		.top {
			margin-bottom: 22px;

			h1 {
				// font-size: 42px;
				font-size: 28px;
				font-weight: 400;
				color: $violet;
			}

			h4 {
				font-size: 26px;
				font-weight: 400;
				margin-bottom: 16px;
			}

			h5 {
				font-size: 18px;
				font-weight: 400;
			}
		}

		.img {
			margin-bottom: 22px;
			
			img {
				max-width: 490px;
				margin: 0 auto;
			}
		}
		
		.content {
			
			p {
				font-size: 18px;
			}
		}

	}

	@include breakpoint(tablet) {
	padding-top: 150px;

		&_header {
			margin: 0 32px 40px 32px;
			background-size: 300px, 200px;
			background-position: 100% 20px, 20px 60px;
			
			.quote {
				padding: 70px 70px 100px 70px;
			}			
		}
		
		&_item {
			display: grid;
			grid-template-columns: 35% 65%;
			grid-template-rows: repeat(2, 1fr);
			grid-gap: 0;
			text-align: left;
			position: relative;
			margin: 0 30px 60px;
			
			&:after {
				content: "";
				position: absolute;
				bottom: -30px;
				background: $violet;
				height: 1px;
				width: 100%;
			}
			
			.top { 
				grid-row: 1 / 2;
				margin:0;
			}
			.img { 
				grid-row: 1 / 3;
				justify-self: end;
				margin: 0;
			}
			.content { 
				grid-row: 2 / 3;
				margin:0;
				align-self: center;
			}
		}
	}
	@include breakpoint(laptop) {
	padding-top: 168px;
	
		&_header {
			margin: 0 32px;
			padding-bottom: 40px;
			// background-size: 600px, 370px;
			// background-position: 100% 20px, 20px 60px;
			
			.quote {
				padding: 70px 100px 100px 120px;
				
				p {
					line-height: 62px;
					font-size: 24px;
					
					span {
						font-size: 62px;
					}
				}				
			}
		}

		&_item {

			.top {

				h1 {
					font-size: 54px;
				}

				h4 {
					font-size: 32px;
				}
			}			
		}	 	
	}
	@include breakpoint(desktop) {
	padding-top: 192px;
			
	}
	@include breakpoint(bigdesktop) {
		
		&_header {
			// margin: 0 32px;
			padding-bottom: 80px;
			// background-size: 600px, 370px;
			// background-position: 100% 20px, 20px 60px;
			
			.quote {
				padding: 70px 10vw 140px 10vw;
			}
		}

		&_items {
			margin: 0 10vw;

		}

		&_item {
			margin: 0 0 60px 0;
		}
	}
}