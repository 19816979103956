// .single {
.treatment_single {
	position: relative;
	background: $white;
	padding-top: 138px;
	
	+ .contact#contacto {
		background: $white;
		padding: 30px;
	}

	.single_img {

		img {
		    height: 300px;
		}
	}

	.single_header {
		background: $white;
		box-shadow: 0 0 16px 8px rgba(0, 0, 0, 0.2);
		padding: 30px;
		margin: -60px 30px 0 30px;
		position: relative;
		z-index: 2;
		color: $violet;
		
		h1 {
			font-weight: normal;
			font-size: 34px;
			margin-bottom: 68px;
		}
	}

	.single_content {
		padding: 30px;
		
		p {
			margin-bottom: 12px;
		}
	}

	.single_video {
		position: relative;
		padding-bottom: 56.25%; /* 16:9 */
		margin: 0;
		height: 0;
		
		iframe {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}	
	}	

	.single_kind {
		padding: 30px;

		h3 {
			font-size: 26px;
			font-weight: normal;
			color: $violet;
			border-top: 1px $grey solid;
			border-bottom: 1px $grey solid;			
			padding: 18px;
			text-align: center;
			margin-bottom: 30px;
		}
		
		input {
			position: absolute;
			opacity: 0;
			z-index: -1;
			width: 0;
			height: 0;
			margin: 0;
		}
		
		// Accordion styles
		.tabs {
			overflow: hidden;
		}

		.tab {
			width: 100%;
			color: white;
			overflow: hidden;
			
			&-label {
				display: flex;
				justify-content: flex-start;
				padding: 2em;
				background: linear-gradient( to bottom, #d7c6e0, #eee4ef);
				font-weight: bold;
				color: $violet;
				cursor: pointer;	
				transition: all .35s ease-in-out;	    

			    .more {
			    	width: 16px;
			    	height: 16px;
			    	fill: $violet;
			    	margin-right: 12px;
			    	transition: all .35s ease-in-out;
			    }
		    	em {
		    		line-height: 20px;
		    		color: $grey;
					padding-right: 8px;
					display: flex;
					align-items: center;
					border-right: 1px $grey solid;
					margin-right: 8px;
					transition: all .35s ease-in-out;		    		
		    	}
		    	span {
		    		line-height: 20px;
		    		transition: all .35s ease-in-out;
		    	}
			    
			    &:hover {
			    	background: $violet;
			    	
			    	em, span {
			    		color: $white;
			    		border-color: $white;
			    	}
			    	.more {
			    		fill: $white;
			    	}
			    }
			}

			&-content {
				max-height: 0;
				padding: 0 1em;
				background: white;
				transition: all .35s;
				color: $grey;
				
				.kind_img {
					border: 1px $grey solid;
					padding: 8px;
					margin-bottom: 18px;
				}
				.kind_column {

					h2 {
						font-size: 24px;
					    margin-bottom: 12px;
					}

					p {
						margin-bottom: 8px;
						
						&:last-child { margin: 0;}
					}

					.tab-mp {
						display: block;
						margin-top: 32px;
						
						span {
							display: block;
							color: $grey;
							margin-bottom: 12px;
						}
					
						.mercadopago {
							width: 160px;
							height: 42px;
						}
					}
				}
			}
		}

		// :checked
		input:checked {

			+ .tab-label {
				background: $violet;
				
				em, span {
		    		color: $white;
		    		border-color: $white;
		    	}
		    	.more {
		    		fill: $white;
					transform: rotate(45deg);
		    	}				
			}
			
			~ .tab-content {
				max-height: 200vh;
				padding: 30px 0;
			}
		}
	}

	@include breakpoint(phablet) {

		.single_header {
			background: $white;
			box-shadow: 0 0 16px 8px rgba(0, 0, 0, 0.1);
			padding: 60px;
			margin: -60px 60px 0 60px;
			position: relative;
			z-index: 2;
			
			h1 {
				font-weight: normal;
				font-size: 34px;
				margin-bottom: 68px;
			}

			p {
				color: $violet;
			}
		}		

		.single_img {
			margin-left: 15%;
			display: block;
		}

		.single_content {
			padding: 60px;
		}

		.single_kind {
			
			.tab-content {
				display: grid;
				grid-template-columns: 1fr 2fr;
				grid-gap: 20px;		
			}
		}
	}

	@include breakpoint(tablet) {
		display: grid;
		grid-template-columns: 1fr 2fr;

		.breadcrumbs {
			grid-column: 1 / 3;
		}
		
		.single_img {
			grid-column: 1 / 3;
		}

		.single_header {
			grid-column: 1 / 2;
			margin: -260px 0 0 60px;
			position: relative;
			
			&:before {
				content: "";
				position: absolute;
				top: 40%;
				left: -100%;
				right: 100%;
				height: 40px; 
				background: $violet-footer;
			}
			
			h1 {
				margin-bottom: 220px;
			}
		}

		.single_content {
			grid-column: 2 / 3;
			columns: 2;
			column-gap: 60px;
			break-inside: avoid;
		}

		.single_video {
			grid-column: 1 / 3;
			margin: 60px;
		}

		.single_kind {
			grid-column: 1 / 3;
			
			.tab-content {
				display: grid;
				grid-template-columns: 1fr 2fr;
				grid-gap: 30px;		
				
				.kind_img {
					height: 300px;
					margin: 0;
				}	
			}

			input:checked {

				~ .tab-content {
					padding: 30px;
				}
			}						
		}		

		+ .contact#contacto {
			padding: 60px;
		}
	}
	@include breakpoint(laptop) {
		padding-top: 168px;

		.breadcrumbs {
			margin-bottom: 20px;
		}
		
		.single_img {
			
			img {
				height: 400px;
			}
		}

		.single_header {
			margin: -360px 0 0 60px;
			
			&:before {
				height: 60px; 
				top: 50%;
			}
			
			h1 {
				font-size: 48px;
				margin-bottom: 290px;
			}

			p {
				font-size: 18px;
			}
		}

		.single_content {
			grid-column: 2 / 3;
			columns: 2;
			column-gap: 60px;
			break-inside: avoid;
		}

		.single_video {
			grid-column: 1 / 3;
			margin: 60px;
		}

		.single_kind {
			padding: 60px;
			
			h3 {
				font-size: 36px;
			}
			
			.tab-content {
				
				.kind_img {
					height: 350px;
				}	
			}

			input:checked {

				~ .tab-content {
					padding: 60px;
				}
			}			
		}		
	}
	@include breakpoint(desktop) {
		padding-top: 192px;
		
	}
	@include breakpoint(bigdesktop) {
		
		.single_header {
			margin-left: 10vw;
		}

		.single_content {
			padding-right: 10vw;
		}

		.single_video {
			margin: 60px 10vw;
		}		

		.single_kind {
			padding: 60px 10vw 60px;
		}

		+ .contact#contacto {
			// background: $white;
			padding: 60px 10vw;
		}		
	}
}