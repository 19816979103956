.header {
	display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
	position: absolute;
	top:40px;
	z-index: 10;
	padding: 20px;
	background: $white;

	.logo-plastica {
		width: 170px;
		height: 58px;
		display: block;
	}

	&_social {
		background: $violet;
		position: absolute;
		top:0;
		left: 0;
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		height: 40px;
		padding: 10px 20px;
		z-index: 12;

		a {
			margin: 0 12px;

			.icon {
				height: 18px;
				width: 18px;
			}
		}

		&.is-active {
		    position: fixed;
		}
	}

	&__toggle {
	    width: 27px;
	}

	&__toggle-line {
	    display: block;
	    background: $ocean;
	    width: 100%;
	    height: 4px;
	    margin-bottom: 4px;
	    opacity: 1; 
	    transition: opacity 600ms ease 200ms, top 300ms ease, left 300ms ease, background 300ms ease, transform 300ms ease 450ms;    
		
		&:nth-child(1) {
		    transform-origin: center;
		}
		&:nth-child(3) {
		    transform-origin: center;
		}
	}    

	&.is-active {
	    position: fixed;
	    z-index: 11;

		.header__toggle-line {

			&:nth-child(1) {
			    transform: translateY(8px) rotate(45deg);
			    // background: $ocean;
			}
			&:nth-child(2) {
			    // background: $ocean;
			    opacity: 0;
			}
			&:nth-child(3) {
			    transform: translateY(-8px) rotate(-45deg);
			    // background: $ocean;
			}
		}
	}

	@include breakpoint(tablet) {
		background: none;
		padding: 26px 32px;
		align-items: center;
		
		&__toggle {
		    display: none;
		}		

		&_social {
			justify-content: flex-end;
		}		
		
		&.fixed {
			position: absolute;
		}

		&.is-active {
		    position: absolute;
		    z-index: 10;
		}
	}
	@include breakpoint(laptop) {

		.logo-plastica {
			width: 220px;
			height: 76px;
		}
	}
	@include breakpoint(desktop) {

		.logo-plastica {
			width: 290px;
			height: 100px;
		}
	}
}

section.menu {
    position: fixed;
    top: 0;
    left: 100%;
    z-index: 11;
    width: 100%;
    height: 100%;
    opacity: 0;
    padding-top: 140px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
    background: rgba(20, 56, 74, .9);
    transition: opacity 400ms ease, left 100ms ease 600ms;
    pointer-events: none;
	
	&.is-visible {
	    left: 0;
	    opacity: 1;
	    transition: left 100ms ease, opacity 400ms ease 200ms;
	    overflow: scroll;
	}
	
	.menu-inner {
	    margin: 0;
		text-align: center;
		pointer-events: auto;
		
		.menu-list {
		    list-style: none;
		    margin: 0;
		    padding: 0;
		    
			li {
			    font-size: 28px;   
			    margin-bottom: 22px;
			    
				span {
				    line-height: 38px;
				}
				a {
				    line-height: 38px;
				    color: $white;
	
					&.tel {
						display: flex;
						align-items: center;
						justify-content: center;

						svg {
							width: 30px;
							height: 30px;
							margin-right: 12px;
						}
					}

					&.line {
						background: transparent;
						width: auto;

						& > span {
						    color: $ocean;
							font-weight: 400;
							font-size: 16px;	
						}
						&:before {
							background: $ocean;
							color: $white;
							font-weight: 400;
							font-size: 16px;
						}
					}
				}

			}
		}
	}

	.gracias & { display: none;}

	@include breakpoint(tablet) {
		position: absolute;
		left: 0;
		top: 80px;
		// z-index: 11;
		opacity: 1;
		background: none;
		padding: 0px 32px;
		height: auto;
		align-items: flex-end;
		
		.menu-inner {
			display: flex;
			
			.menu-list {
			    display: flex;
			    
				li {
				    font-size: 12px;   
				    line-height: 1.6;
				    margin: 0;
					
					&.inicio { display: block}

					span {
					    line-height: 1;
					}
					a {
					    line-height: 32px;
					    color: $grey;
					    padding: 0 12px;
					    transition: color 400ms ease;
					    
					    &:hover {
					    	color: $grey;
					    }

					    .fixed & {
							color: $white;
					    }

						&.line {
							margin-left: 40px;

							& > span {
								font-size: 12px;	
							}
							&:before {
								font-size: 12px;
								line-height: 1;
							}	
						}	    
					}
				}
			}	
		}

		&.fixed {
		    top: 0;
			padding: 13px 20px;
			background: $white;
			position: fixed;
			animation: headerFix .5s ease-out;
		}
	}
	@include breakpoint(laptop) {	
		top: 86px;

		.menu-inner {
			
			.menu-list {
			    
				li {

					a {
					    padding: 0 28px;
					    font-size: 18px;
					}
				}
			}
		}
	}
	@include breakpoint(desktop) {
		top: 98px;		
	}
}

@keyframes headerFix {
  0%   { top: -100px; }
  100% { top: 0; }
}