.footer {
	padding: 60px;
	background: $violet-footer;

	&_menu {
		display: none;
		text-align: center;
		background: $white;
		padding: 20px;
		margin-bottom: 28px;

		.turnos {
			color: $violet-dark;
			font-weight: 700;
			font-size: 18px;
			display: flex;
			align-items: center;
			justify-content: center;
			margin-bottom: 24px;
			max-width: 250px;
			
			.icon {
				fill: $ocean;
				margin-right: 12px;
			}

			&:hover {
				color: $ocean;
			}
		}
		
		.whatsapp {
		    color: $green;
			font-weight: 700;
			font-size: 18px;
			display: flex;
			align-items: center;
			justify-content: center;
			max-width: 250px;

			.icon {
				margin-right: 12px;
			}

			&:hover {
				color: $ocean;
			}
		}

		.icon {
			width: 26px;
			height: 26px;
		}

		.page-template-page-home &, .page-template-page-tratamientos & {
			display: block;
		}
	}

	&_where {
		border-bottom: 1px $violet-footer-light solid;
		color: $violet-footer-light;
		padding-bottom: 16px;		
		margin-bottom: 28px;
	}

	&_address {
		color: $grey;
		
		li {
			margin-bottom: 36px;
			
			&.social {
				display: flex;
				justify-content: space-between;
				align-items: center;
				
				a {
					flex: 26px 0 0;
					
					.icon {
						fill: $white;
						transition: fill .3s ease;
					}
					
					&:hover {
						
						.icon {
							fill: $violet-dark;
						}	
					}

					&.fb {
						
						.icon {
							width: 18px;
						}
					}

				}
			}

			.stamp {
				max-width: 150px;
			}
		}

		h4 {
			font-weight: 700;
			// font-size: 24px;
			font-size: 22px;
			margin-bottom: 12px;
		}

		p {
			color: $violet-footer-light;
			// font-size: 18px;
			font-size: 16px;
			margin-bottom: 6px;

			a {
				color: $violet-footer-light;
				
				&:hover {
					color: $white;
				}
			}

			strong {
				font-weight: 400;
				color: $violet-footer-dark;
			}

			&.tel {
				background: url('../img/bg-address-tel.svg') no-repeat;
				background-size: 18px;
				background-position: center left;
				padding-left: 28px;
			}

			&.street {
				background: url('../img/bg-address-street.svg') no-repeat;
				background-size: 16px;
				background-position: center left;
				padding-left: 28px;
			}

			&.street2 {
				padding-left: 28px;
			}

			&.email {
				background: url('../img/bg-address-email.svg') no-repeat;
				background-size: 18px;
				background-position: center left;
				padding-left: 28px;
			}
		}
	}

	&_copy {
		color: $violet-footer-dark;
		
		a {
			color: $violet-footer-dark;
			text-decoration: underline;	
		}
	}

	@include breakpoint(phablet) {

		&_menu {
		
			ul {
				display: flex;
				justify-content: space-between;
				align-items: center;	
				
				.turnos {
					margin: 0;
					max-width: none;
				}

				.whatsapp {
					max-width: none;
				}
			}
		}
	
		&_address {
			display: grid;
		    grid-template-columns: repeat(2, 1fr);
		    grid-gap: 20px;
		    
		    li.social {
		    	justify-content: flex-start;
		    	
		    	a {
		    		margin-right: 26px;
		    	}
		    }
		}
	}
	@include breakpoint(laptop) {

		&_menu {
		
			ul {
				
				.turnos {
					font-size: 24px;
				}

				.whatsapp {
					font-size: 24px;
				}				
			}
		}		

		&_address {
		    display: flex;
		    justify-content: space-around;
		    
		    li {

			    &.social {
			    	flex-direction: column;
			    	flex: auto 0 0;
			    	
			    	a {	margin: 0 0 42px 0; }

			    	.icon {
			    		width: 22px;
			    		
			    	}
		    		
		    		a.fb .icon {
		    			width: 14px;
		    		}
			    }
			}
	    }
	}
	@include breakpoint(bigdesktop) {
		padding: 60px 10vw;
	}
}